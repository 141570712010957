import axios from "axios";

const CATEGORY_URL = 'api/v1/categories'

export default class CategoryService {
  getDropdown() {
    return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${CATEGORY_URL}/ddl`).then(res => res.data);
  }

  getAll(queryParam) {
    return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${CATEGORY_URL}`, {params: queryParam}).then(res => res.data)
  }

  getOne(id) {
    return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${CATEGORY_URL}/${id}`).then(res => res.data)
  }

  create(formData) {
    return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${CATEGORY_URL}/`, formData).then(res => res.data)
  }

  uploadImage(id, formData) {
    return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${CATEGORY_URL}/${id}/upload/image`, formData)
  }

  update(id, data) {
    return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${CATEGORY_URL}/${id}`, data)
  }
}
