<template>
  <FormKit
      type="form"
      id="categories"
      :actions="false"
      v-model="initValue"
      :classes="{messages: 'tw-text-red-500 tw-text-center tw-mt-4'}"
      @submit="submit"
  >
    <FormKit
        name="name"
        type="text"
        label="Nama"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
    />
  </FormKit>
  <div class="p-fluid tw-mt-12 tw-mb-4">
    <Button label="Submit" @click="submitData"/>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, watch, ref} from "vue";
import {submitForm} from "@formkit/vue";

import {defaultFormikListClass} from "@/constants/formik";

const emit = defineEmits(['submit'])
const props = defineProps({
  initialData: {
    type: Object,
    required: false
  }
})
const initValue = ref(props.initialData)

const submit = (data) => {
  const formData = {
    ...data,
  }
  emit('submit', formData)
}

watch(() => props.initialData, (newData) => {
  initValue.value = newData
})

const submitData = () => {
  submitForm('categories')
}
</script>
