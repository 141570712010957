<template>
  <div v-if="props.context.src === null">
    <FileUpload v-if="!blobUrl" mode="basic" :custom-upload="true" name="image" accept="image/*"
                :max-file-size="10000000" @select="fileSelected"/>
    <div v-else>
      <div class="tw-flex tw-justify-end tw-mb-5">
        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                @click="removeMedia"/>
      </div>
      <Image :src="blobUrl"/>
    </div>
  </div>
  <div v-else>
    <div v-if="!editMode">
      <div class="tw-flex tw-justify-end">
        <Button icon="pi pi-pencil" class="p-button-rounded p-button-info" @click="toggleEdit"/>
      </div>
      <Image :src="props.context.src"/>
    </div>
    <div v-else>
      <div class="tw-flex tw-justify-end tw-space-x-2">
        <Button :disabled="!blobUrl" icon="pi pi-check" class="p-button-rounded p-button-success" @click="updateImage(true)"/>
        <Button icon="pi pi-times" class="p-button-rounded p-button-danger" @click="updateImage(false)"/>
      </div>
      <FileUpload v-if="!blobUrl" mode="basic" :custom-upload="true" name="image" accept="image/*"
                  :max-file-size="10000000" @select="fileSelected"/>
      <Image v-if="!loading && blobUrl" :src="blobUrl"/>
      <div v-if="loading" class="tw-flex tw-justify-center">
        <ProgressSpinner stroke-width="5" animation-duration="2s"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";

import {useMediaUtils} from "@/composables/utils";

const editMode = ref(false)
const loading = ref(false)
const props = defineProps({
  context: Object,
})

const {uploadCustomFileUpload, removeMedia, blobUrl, media, mediaSelected} = useMediaUtils()

const toggleEdit = () => {
  editMode.value = !editMode.value
}

const updateImage = async (isApprove=false) => {
  if (isApprove) {
    loading.value = true
    props.context.node.input(media.value)
    await props.context.upload(media.value)
  }
  loading.value = false
  toggleEdit()
  removeMedia()
}

const fileSelected = (event) => {
  uploadCustomFileUpload(event)
  if (mediaSelected.value) {
    props.context.node.input(media.value)
  } else {
    props.context.node.input(null)
  }
}
</script>