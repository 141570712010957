import CategoryService from "@/service/CategoryService";
import {onMounted, ref} from "vue";

export function useCategoryDropdown() {
    const categoryService = new CategoryService();

    const categories = ref([]);

    const getCategoryDropdown = async () => {
        const {data} = await categoryService.getDropdown();
        const tempData = {}

        data?.categories.forEach((category) => {
            tempData[category.id] = category.name
        })

        categories.value = tempData
    }

    onMounted(async () => {
        await getCategoryDropdown()
    })

    return {categories, getCategoryDropdown}
}

export function useCategoryList() {
    const categories = ref([]);
    const totalCategories = ref(0);
    const categoryService = new CategoryService();

    const getCategories = async (query) => {
        const res = await categoryService.getAll(query)
        const data = res.data
        const temp = []
        data.categories.forEach((category, idx) => {
            temp.push({...category, no: idx + 1})
        })
        categories.value = temp
        totalCategories.value = data.total
    }

    return {categories, totalCategories, getCategories}
}

export function useCategoryDetail() {
    const detail = ref({})
    const categoryService = new CategoryService();

    const getDetail = async (id) => {
        const res = await categoryService.getOne(id)
        let tempData = {}
        Object.keys(res.data).forEach((key) => {
            if (typeof res.data[key] === 'boolean') tempData[key] = res.data[key]
            else tempData[key] = String(res.data[key])
        })
        detail.value = tempData
    }

    return {detail, getDetail}
}

export function useCategoryForm() {
    const categoryService = new CategoryService();

    const create = async (data) => {
        await categoryService.create(data)
    }

    const update = async (id, data) => {
        await categoryService.update(id, data)
    }

    const updateImage = async (id, image) => {
        const formData = new FormData()
        formData.append('image', image)
        formData.append('categoryID', id)
        await categoryService.uploadImage(id, formData)
    }

    return {create, update, updateImage}
}
