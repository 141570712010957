<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Edit Kategori</h1>
    <div>
      <FormKit
          v-model="data"
          :type="uploadImage"
          name="image"
          label="Foto"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          :src="detail.image"
          :upload="updateServiceImage"
      />
      <CategoryForm @submit="submitCategory" :initial-data="detail"/>
    </div>
  </div>
</template>

<script setup>
import {useCategoryDetail, useCategoryForm} from "@/composables/category";
import {useRoute, useRouter} from "vue-router";
import CategoryForm from "./Form";
import {onBeforeMount, ref} from "vue";
import {createInput} from "@formkit/vue";
import FormUploadImage from "@/components/form/FormUploadImage";
import {defaultFormikClass} from "@/constants/formik";

const data = ref(null)
const uploadImage = createInput(FormUploadImage, {
  props: ['src', 'upload'],
})
const {update} = useCategoryForm()
const {detail, getDetail} = useCategoryDetail()
const {updateImage} = useCategoryForm()
const router = useRouter();
const route = useRoute()

const submitCategory = async (data) => {
  await update(route.params.id, data)
  await router.push({name: 'categories'})
}

const updateServiceImage = async (data) => {
  await updateImage(route.params.id, data)
  await getDetail(route.params.id)
}

onBeforeMount(async () => {
  await getDetail(route.params.id)
})
</script>