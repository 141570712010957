import {ref} from "vue";

export function useCommonUtils() {
    const formatCurrency = (value) => {
        if (value) return value.toLocaleString('id-ID', {style: 'currency', currency: 'IDR'});
        return 'Rp0,-'
    }

    return {formatCurrency}
}

export function useMediaUtils() {
    const media = ref(null)
    const blobUrl = ref(null)
    const mediaSelected = ref(false)
    const isSubmitted = ref(false)

    const uploadImage = (newMedia) => {
        mediaSelected.value = false
        media.value = newMedia
        if (newMedia.length > 0) mediaSelected.value = true
    }

    const uploadCustomFileUpload = (event) => {
        media.value = event.files[0]
        mediaSelected.value = true
        blobUrl.value = event.files[0].objectURL
    }

    const removeMedia = () => {
        media.value = null
        mediaSelected.value = false
        blobUrl.value = null
    }
    return {media, mediaSelected, blobUrl, isSubmitted, uploadImage, uploadCustomFileUpload, removeMedia}
}